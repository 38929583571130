import { T } from "@/locales";
import moment from "moment";

export const columns = [
  {
    title: T("序号"),
    dataIndex: "index",
    key: "index",
    customRender: (value, item, index) => {
      return index + 1;
    },
  },
  {
    title: T("货主"),
    dataIndex: "client_name",
    key: "client_name",
  },
  {
    title: T("库位"),
    dataIndex: "location_number",
    key: "location_number",
  },
  {
    title: T("产品名称"),
    dataIndex: "material_name",
    key: "material_name",
    ellipsis: true,
  },
  {
    title: T("产品编号"),
    dataIndex: "material_number",
    key: "material_number",
  },
  {
    title: T("批次编号"),
    dataIndex: "batch_number",
    key: "batch_number",
  },
  {
    title: T("质检状态"),
    dataIndex: "status_display",
    key: "status_display",
  },
  {
    title: T("入库日期"),
    dataIndex: "stock_in_date",
    key: "stock_in_date",
  },
  {
    title: T("库龄"),
    dataIndex: "stock_age",
    key: "stock_age",
    customRender: (_, item) => (item.stock_in_date ? moment().diff(moment(item.stock_in_date), "days") : ""),
  },
  {
    title: T("账面数量"),
    key: "book_quantity",
    dataIndex: "book_quantity",
  },
  {
    title: T("已分配数量"),
    dataIndex: "assigned_quantity",
  },
  {
    title: T("可分配数量"),
    dataIndex: "assignable_quantity",
  },
  {
    title: T("操作"),
    dataIndex: "action",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
